@value lightText: #fff;
@value darkText: var(--color-charcoal-alpha-85);

.button {
  line-height: 1;
  font-family: inherit;
  gap: 4px;
  border: none;
  transition:
    color 0.3s ease,
    background-color 0.3s ease;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.fill {
  width: 100%;
}

.primary {
  background-color: var(--color-violet-strong);
  color: var(--color-white);
}

.primary:not(.disabled):hover,
.primary:not(.disabled):active,
.primary:not(.disabled):focus {
  background-color: var(--color-violet-dark);
}

.primary.disabled {
  color: var(--color-white-alpha-45);
}

.primaryGradient {
  background: linear-gradient(
    180deg,
    var(--color-violet-strong) 0%,
    var(--color-violet-bright) 100%
  );
  color: var(--color-white);
}

.primaryGradient:not(.disabled):hover,
.primaryGradient:not(.disabled):active,
.primaryGradient:not(.disabled):focus {
  background-color: var(--color-violet-dark);
}

.primaryGradient.disabled {
  color: var(--color-white-alpha-45);
}

.plusGradient {
  background: url('./plus_gradient.webp') center;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--color-white);
}

.plusGradient:not(.disabled):hover,
.plusGradient:not(.disabled):active,
.plusGradient:not(.disabled):focus {
  background-color: var(--color-violet-dark);
}

.plusGradient.disabled {
  color: var(--color-white-alpha-45);
}

.palette {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.palette:not(.disabled):hover,
.palette:not(.disabled):active,
.palette:not(.disabled):focus {
  background-color: var(--color-primary-tint-dark);
}

.palette.disabled {
  background-color: var(--color-charcoal-alpha-7);
  color: var(--color-charcoal-alpha-45);
}

.opaque {
  background-color: var(--color-charcoal-alpha-7);
  color: var(--color-charcoal-90);

  /* TODO: talk to design about performance. */

  /* backdrop-filter: blur(32px); */
}

.opaque:not(.disabled):hover,
.opaque:not(.disabled):active,
.opaque:not(.disabled):focus {
  background-color: var(--color-charcoal-alpha-15);
}

.opaque.disabled {
  background-color: var(--color-charcoal-alpha-7);
  color: var(--color-charcoal-alpha-45);
}

/*
 * Note: These are adhoc dark mode settings - intended as overrides.
 * Reconsider implementation when dark mode is properly implemented.
 */
.darkMode.opaque {
  background-color: var(--color-white-alpha-15);
  color: var(--color-white);
}

.darkMode.opaque:not(.disabled):hover,
.darkMode.opaque:not(.disabled):active,
.darkMode.opaque:not(.disabled):focus {
  background-color: var(--color-white-alpha-30);
}

.darkMode.opaque.disabled {
  background-color: var(--color-white-alpha-15);
  color: var(--color-white-alpha-45);
}

.transparent {
  background-color: transparent;
  color: var(--color-charcoal-90);

  /* TODO: talk to design about performance. */

  /* backdrop-filter: blur(32px); */
}

.transparent:not(.disabled):hover,
.transparent:not(.disabled):active,
.transparent:not(.disabled):focus {
  background-color: transparent;
}

.transparent.disabled {
  background-color: transparent;
  color: var(--color-charcoal-alpha-45);
}

/*
 * Note: These are adhoc dark mode settings - intended as overrides.
 * Reconsider implementation when dark mode is properly implemented.
 */
.darkMode.transparent {
  background-color: transparent;
  color: var(--color-white);
}

.darkMode.transparent:not(.disabled):hover,
.darkMode.transparent:not(.disabled):active,
.darkMode.transparent:not(.disabled):focus {
  background-color: transparent;
}

.darkMode.transparent.disabled {
  background-color: transparent;
  color: var(--color-white-alpha-45);
}

.mono {
  background-color: var(--color-white);
  color: var(--color-charcoal-90);
}

.mono:not(.disabled):hover,
.mono:not(.disabled):active,
.mono:not(.disabled):focus {
  background-color: var(--color-white-alpha-85);
}

.mono.disabled {
  color: var(--color-charcoal-alpha-45);
}

.truncate {
  max-width: 100%;
}

.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.extraExtraSmall {
  min-height: 24px;
  min-width: 24px;
  border-radius: 12px;
  padding: var(--spacing-0-5);
  font-size: 10px;
  font-weight: 700;
}

.extraExtraSmall.truncate {
  height: 24px;
}

.extraSmall {
  min-height: 32px;
  border-radius: var(--radius-sm);
  padding: var(--spacing-1-25);
  font-size: 12px;
  font-weight: 700;
}

.extraSmall.truncate {
  height: 32px;
}

.extraSmall.circle {
  width: 32px;
  border-radius: 32px;
}

/* TODO(PB:17135149): Expose the common sizes in CSS custom properties so
as not to hardcode the value here */
.small {
  min-height: 32px;
  border-radius: var(--radius-sm);
  padding: var(--spacing-1);
  font-size: 12px;
  line-height: 1.3;
  font-weight: 700;
}

.small.truncate {
  height: 32px;
}

.small.circle {
  width: 32px;
  border-radius: 32px;
}

.medium {
  min-height: 40px;
  border-radius: var(--radius-md);
  font-size: 12px;
  line-height: 1.3;
  font-weight: 700;
  padding: var(--spacing-1-5);
}

.medium.truncate {
  height: 40px;
}

.medium.circle {
  width: 40px;
  border-radius: 40px;
}

.large {
  min-height: 48px;
  border-radius: var(--radius-lg);
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 1.3;
  font-weight: 700;
  padding: 0 var(--spacing-1-5);
}

.large.truncate {
  height: 48px;
}

.large.circle {
  width: 48px;
}

.button.fab {
  height: 48px;
  width: 48px;
  padding: var(--spacing-1-5);
  border-radius: 50%;
}

.btnGroup > .button {
  border-radius: 0;
}

.button:hover {
  cursor: pointer;
}

.button.disabled,
.button:disabled,
.button:disabled:hover {
  cursor: not-allowed;
}

.button.circle {
  border-radius: 50%;
  padding: 0;
}

.btnGroup {
  width: fit-content;
  height: 100%;
  border-radius: var(--radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnGroup > .button:first-child {
  border-top-left-radius: var(--radius-md);
  border-bottom-left-radius: var(--radius-md);
}

.btnGroup > .button:last-child {
  border-top-right-radius: var(--radius-md);
  border-bottom-right-radius: var(--radius-md);
}
