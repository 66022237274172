.body {
  /* These styles are required by the app to ensure no pull-to-refresh 
    functionality. `overscroll-behavior: none` alone doesn't seem to do 
    the trick in mobile Safari 16. */
  overflow: hidden;
  height: 100%;
}

.wrapper {
  /* fallback to 100vh if dvh is not available */
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
}

@supports (-webkit-fill-available: none) {
  .wrapper {
    height: -webkit-fill-available;
    height: 100dvh;
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.scroll {
  overflow: scroll;
}

.noScroll {
  overflow: hidden;
}

.grow {
  flex-grow: 1;
}

.fill {
  height: 100%;
  /* Mobile Safari doesn't respect height: 100% 
    when none of the ancestors have set height in units */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* So that the child with `overflow: scroll` is scrollable */
  min-height: 0;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;
}
